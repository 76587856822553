import React from "react";
import { Box, Typography, Button, Divider, Dialog } from "@mui/material";

const CustomerConfirmationDialog = ({
  currentCustomer,
  open,
  handleClose,
  handleOpenEditCustomerDialog,
}) => {
  const consoleCustomer = () => {
    ////console.log(currentCustomer);
  };

  return (
    <Dialog onClose={handleClose} open={open} maxWidth={600}>
      <Box style={{ padding: "25px" }}>
        <div className="wrapperData">
          <Box
            lg={{
              textAlign: "center",
            }}
          >
            <Typography
              variant="h2"
              lg={{
                width: "100%",
                textAlign: "center",
              }}
            >
              Confirme seus dados
            </Typography>
          </Box>
          <Divider style={{ marginTop: "25px", marginBottom: "25px" }} />
          <Box>
            <Typography variant="h6">
              <strong>Meu nome é:</strong>
            </Typography>
            <Typography variant="h3">
              <strong>{currentCustomer.name}</strong>
            </Typography>
          </Box>
          <Divider style={{ marginTop: "25px", marginBottom: "25px" }} />
          <Box>
            <Typography variant="h6">
              <strong>Meu telefone é:</strong>
            </Typography>
            <Typography variant="h3">
              <strong>{currentCustomer.phone}</strong>
            </Typography>
          </Box>
        </div>
        <Box
          sx={{
            textAlign: "center",
          }}
        >
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              handleClose();
              handleOpenEditCustomerDialog();
            }}
            fullWidth
            sx={{
              mr: 1,
              mb: 1,
            }}
          >
            Editar informações
          </Button>
        </Box>
        <Box
          sx={{
            textAlign: "center",
          }}
        >
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={() => handleClose()}
            sx={{
              mr: 1,
              mb: 1,
            }}
          >
            Confirmar
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};
export default CustomerConfirmationDialog;
