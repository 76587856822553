import React from "react";
import { useEffect, useState, useRef } from "react";
import {
  Card,
  CardContent,
  CardMedia,
  Chip,
  Typography,
  TextField,
  Grid,
  Box,
} from "@mui/material";
import { Link, NavLink } from "react-router-dom";
import { useOutletContext, useParams } from "react-router-dom";
import { useApp } from "../../../context/appContext";
import locations from "../../../services/locations";
import { IngredientsBowl } from "../../../assets/svgIcons/icons";
import ImageCard from "./imageCard";
import currency from "currency-formatter";
import { SmsFailedSharp } from "@mui/icons-material";
import "./productListStyles.css";
import ProductPrice from "./productPrice";
import Navbar from "../Navbar";

const ProductsList = (props) => {
  const { restaurantName } = useParams();

  const [searchTerm, setSearchTerm] = useOutletContext();

  const { state } = useApp();

  const observerRefs = useRef([]);

  const newProductsList = state.categories.map((cat) => {
    return {
      category_id: cat._id,
      category: cat.name,
      products: state.products.length
        ? state.products
            .filter((p) => cat.items.includes(p._id))
            .sort((a, b) => b.price - a.price)
            .map((prod) => {
              ////console.log(prod);
              if (prod.offer) {
                return {
                  id: prod._id,
                  title: prod.name,
                  image: prod.image,
                  description: prod.description,
                  price: prod.price,
                  complementGroups: state.complementGroups
                    .filter((cg) => prod.complements.includes(cg._id))
                    .map((cgroup) => {
                      return {
                        _id: cgroup._id,
                        name: cgroup.name,
                        mandatory: cgroup.mandatory,
                        pdvCode: cgroup.pdvCode,
                        rules: {
                          min: cgroup.minQuantity,
                          max: cgroup.maxQuantity,
                        },
                        //FAZER A DIVISÃO AQUI
                        complements: cgroup.fractionalItems
                          ? state.complements
                              .filter((c) => cgroup.complements.includes(c._id))
                              .map((item) => {
                                ////console.log("item", item);
                                return {
                                  ...item,
                                  price: item.price / cgroup.fractions,
                                };
                              })
                          : state.complements.filter((c) =>
                              cgroup.complements.includes(c._id)
                            ),
                        active: cgroup.active,
                      };
                    }),
                  isOffer: true,
                  offerPrice: prod.offerPrice,
                };
              } else {
                return {
                  id: prod._id,
                  title: prod.name,
                  image: prod.image,
                  description: prod.description,
                  price: prod.price,
                  complementGroups: state.complementGroups
                    .filter((cg) => prod.complements.includes(cg._id))
                    .map((cgroup) => {
                      return {
                        _id: cgroup._id,
                        name: cgroup.name,
                        mandatory: cgroup.mandatory,
                        pdvCode: cgroup.pdvCode,
                        rules: {
                          min: cgroup.minQuantity,
                          max: cgroup.maxQuantity,
                        },
                        complements: cgroup.fractionalItems
                          ? state.complements
                              .filter((c) => cgroup.complements.includes(c._id))
                              .map((item) => {
                                return {
                                  ...item,
                                  price: item.price / cgroup.fractions,
                                };
                              })
                          : state.complements.filter((c) =>
                              cgroup.complements.includes(c._id)
                            ),
                        active: cgroup.active,
                      };
                    }),
                };
              }
            })
        : [],
    };
  });

  return (
    <>
      <Box>
        <Navbar observerRefs={observerRefs} categories={newProductsList} />
      </Box>
      <Box>
        {newProductsList.filter(
          (c) =>
            c.products.filter((p) =>
              p.title.toLowerCase().includes(searchTerm.toLowerCase())
            ).length > 0
        ).length > 0 ? (
          <Grid
            style={{
              paddingLeft: "0px",
              paddingRight: "0px",
            }}
            container
            spacing={0}
          >
            {newProductsList.map((category, key) => {
              return (
                <>
                  {category.products.filter((p) =>
                    p.title.toLowerCase().includes(searchTerm.toLowerCase())
                  ).length > 0 && (
                    <Grid
                      key={category.category_id}
                      item
                      xs={12}
                      lg={12}
                      style={{ marginBottom: "50px" }}
                    >
                      <Box>
                        <Typography
                          ref={(el) => (observerRefs.current[key] = el)}
                          variant="h3"
                          sx={{
                            margin: "15px",
                            color: "primary.main",
                          }}
                        >
                          {category.category}
                        </Typography>
                      </Box>
                      <Grid container spacing={0}>
                        {category.products
                          .filter((p) =>
                            p.title
                              .toLowerCase()
                              .includes(searchTerm.toLowerCase())
                          )
                          .map((product) => {
                            ////console.log(product);
                            return (
                              <Grid
                                style={{ margin: "0", padding: "0" }}
                                key={product.id}
                                item
                                xs={12}
                                lg={6}
                              >
                                <Link
                                  to={`/${restaurantName}/produto/${product.id}`}
                                  style={{ textDecoration: "none" }}
                                >
                                  <Card
                                    variant="elevation"
                                    className="mobileItem"
                                    xs={{
                                      margin: 0,
                                    }}
                                    sx={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      paddingBottom: "0",
                                      p: 0,
                                      borderRadius: "5px",
                                    }}
                                  >
                                    <Box>
                                      <CardContent>
                                        <Typography
                                          sx={{
                                            fontWeight: "600",
                                          }}
                                        >
                                          {product.title}
                                        </Typography>
                                        <Typography
                                          variant="h6"
                                          sx={{
                                            fontSize: "15px",
                                            fontWeight: "500",
                                          }}
                                        >
                                          {product.description &&
                                          product.description.length > 60
                                            ? `${product.description.substring(
                                                0,
                                                60
                                              )}...`
                                            : product.description}
                                        </Typography>

                                        <ProductPrice product={product} />
                                        {product.isOffer && (
                                          <Chip
                                            sx={{
                                              pl: "4px",
                                              pr: "4px",
                                              backgroundColor: "#fc4b6c",
                                              color: "#fff",
                                              borderRadius: "5px",
                                            }}
                                            size="small"
                                            label="OFERTA"
                                          />
                                        )}
                                      </CardContent>
                                    </Box>
                                    <Box>
                                      <ImageCard product={product} />
                                    </Box>
                                  </Card>
                                </Link>
                              </Grid>
                            );
                          })}
                      </Grid>
                    </Grid>
                  )}
                </>
              );
            })}
          </Grid>
        ) : (
          <Grid container spacing={0}>
            <Typography
              variant="h3"
              style={{
                textAlign: "center",
                marginTop: "50px",
                marginBottom: "50px",
                width: "100%",
              }}
            >
              Nada encontrado
            </Typography>
          </Grid>
        )}
      </Box>
    </>
  );
};

export default ProductsList;
