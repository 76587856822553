import React from "react";
import { useState, useEffect } from "react";
import {
  Grid,
  Box,
  Typography,
  Button,
  Divider,
  Dialog,
  Snackbar,
  Alert,
} from "@mui/material";
import { Link, NavLink, useNavigate, useParams } from "react-router-dom";
import CustomerConfirmationDialog from "./components/customerConfirmationDialog";
import AddressDialog from "./addresses/AddressDialog";
import CardDialog from "./addresses/CardDialog";
import CashDialog from "./addresses/CashDialog";
import UserAddresses from "./addresses/UserAddresses";
import OrderHeader from "./components/orderHeader";
import DeliveryInfoOverview from "./components/deliveryInfo";
import PaymentMethods from "./components/paymentMethods";
import SignInEdit from "../SignIn/SignInEdit";
import GeneralNotes from "./components/generalNotes";
import SendingOrderDialog from "./components/sendingOrderDialog";
import {
  calculateHaversineDistance,
  isPointInsidePolygon,
  calculateDistance,
} from "../../utils/distances";

import { useApp } from "../../context/appContext";
import { pointByAddress } from "../../utils/geoLocation";
import { SentimentDissatisfied } from "@mui/icons-material";
import CheckoutFooter from "./components/checkoutFooter";
import calculateTotalPrice from "../../utils/calculateTotalCart";
import CouponInfo from "./couponInfo";
import calculatePreparationTime from "../../utils/calculatePreparationTime";
import PixDialog from "./addresses/PixDialog";
import { getCurrentPosition } from "../../utils/getCurrentLocation";
import calculateCouponDiscount from "../../utils/calculateCouponDiscount";
import TaxPayerInfo from "./taxPayerInfo";

const CheckOut = () => {
  const navigate = useNavigate();

  const { restaurantName } = useParams();

  const {
    state,
    getData,
    postData,
    patchData,
    removeData,
    updateCart,
    registerSessionActivity,
    setLastOrder,
    setAddress,
  } = useApp();

  const [open, setOpen] = useState(false);
  //METODO DE PAGAMENTO
  const [selectedValue, setSelectedValue] = useState(null);

  //METODO IFOOD
  const [selectedIfoodValue, setSelectedIfoodValue] = useState(null);

  const [deliveryFee, setDeliveryFee] = useState(
    state.deliveryInfo && state.deliveryInfo.price
      ? state.deliveryInfo.price
      : 0
  );
  const [deliveryFeeTime, setDeliveryFeeTime] = useState(
    state.deliveryInfo && state.deliveryInfo.deliveryTime
      ? state.deliveryInfo.deliveryTime
      : 0
  );
  const [disabledDelivery, disableDelivery] = useState(false);
  const [selectedCardBrad, setCardBrand] = useState(null);
  const [cashChange, setCashChange] = useState(0);
  const [openAddressDialog, setAddressDialog] = useState(false);
  const [openUserAddressesDialog, setUserAddressesDialog] = useState(false);
  const [openCardDialog, setCardDialog] = useState(false);
  const [openCashDialog, setCashDialog] = useState(false);
  const [openPixDialog, setPixDialog] = useState(false);
  const [editCustomerDialog, setEditCustomerDialog] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState(state.address);
  const [generalNotes, setGeneralNotes] = useState("");

  const [taxPayerId, setTaxPayerId] = useState(null);

  const translatePayment = (input) => {
    const nums = {
      DEBIT: "DÉBITO",
      CREDIT: "CRÉDITO",
      CASH: "DINHEIRO",
    };
    return nums[input];
  };

  const preparedItems = () => {
    const items = state.cart.map((item) => {
      return {
        _id: item._id,
        name: item.name,
        price: item.originalPrice,
        pdvCode: item.pdvCode,
        quantity: item.quantity,
        complements: item.complements.map((cg) => {
          return {
            _id: cg._id,
            name: cg.name,
            pdvCode: cg.pdvCode,
            items: cg.items.map((cgItems) => {
              return {
                _id: cgItems._id,
                name: cgItems.name,
                quantity: cgItems.quantity,
                price: cgItems.price,
                pdvCode: cgItems.pdvCode,
              };
            }),
          };
        }),
      };
    });
    /* //console.log("prepared items");
    //console.log(items); */
  };

  const sendOrder = async () => {
    const totalAmount = state.appliedCoupon
      ? calculateTotalPrice(state.cart) -
        calculateCouponDiscount(
          calculateTotalPrice(state.cart),
          state.appliedCoupon
        )
      : calculateTotalPrice(state.cart);

    //const totalAmount = calculateTotalPrice(state.cart);

    /* //console.log(
      "discount from checkout",
      calculateCouponDiscount(
        calculateTotalPrice(state.cart),
        state.appliedCoupon
      )
    ); */

    let payload = {
      company: state.branch.company,
      modality: state.consumeType,
      branch: state.branch._id,
      items: state.cart,
      notes: generalNotes,
      address: selectedAddress ? selectedAddress : {},
      amount: totalAmount,
      deliveryFee: deliveryFee,
      uuid: state.uuid, //MUDAR ISSO AQUI - CASO O CLIENTE FAÇA MAIS DE UM PEDIDO POR SESSÃO
      customer: state.customer, //AQUI NA API PROCURAR PELO NUMERO, SE NÃO HOUVER FAZER UM BEFORE HOOK CADASTRANDO
      paymentMethod: {
        title: selectedValue,
      },
      status: 1,
    };

    const paymentMethodsDefault = state.branch.paymentMethodsDefault;

    const cash = paymentMethodsDefault.filter((p) => p.name === "cash");
    const pix = paymentMethodsDefault.filter((p) => p.name === "pix");

    if (taxPayerId && taxPayerId !== "") {
      payload.cpf = taxPayerId;
    }

    if (state.appliedCoupon) {
      payload.coupon = state.appliedCoupon;
    }

    ///ENTREGA FACIL
    if (
      state.deliveryInfo &&
      state.deliveryInfo.type &&
      state.deliveryInfo.type === "ifood"
    ) {
      payload.entregaFacilData = state.deliveryInfo.quote;
    }
    ///ENTREGA FACIL

    if (selectedValue === "card") {
      payload.paymentMethod = {
        title: "Cartão na Entrega",
        selectedCardType: selectedCardBrad.name,
        type: "card",
        externalCode: selectedCardBrad.pdvCode,
      };
    }

    if (selectedValue === "cash") {
      payload.paymentMethod = {
        title: "Dinheiro",
        change: cashChange,
        type: "cash",
        externalCode: cash[0].pdvCode,
      };
    }

    if (selectedValue === "pix") {
      payload.paymentMethod = {
        title: "Pix",
        type: "pix",
        externalCode: pix[0].pdvCode,
      };
    }

    if (selectedValue === "ifood") {
      const payment = state.deliveryInfo.quote.paymentMethods.filter(
        (p) => p.id === selectedIfoodValue
      )[0];

      //console.log("found payment", payment);

      payload.paymentMethod = {
        title: "Pagamento na Entrega - Ifood",
        type: "ifood",
        selectedCardType: payment.brand
          ? `${payment.brand} - ${payment.method}`
          : payment.method,
        iFoodPaymentId: payment.id,
        change: cashChange,
      };
    }

    //REMOVE LASTORDERS FROM CUSTOMER TO AVOID LONG ORDER JSON
    delete payload.customer.lastOrder;

    payload.origin = "cardapio-web";

    ////console.log("order payload", payload);

    const resp = await postData("manager/orders", payload);

    return resp;
  };

  const currentCustomer = state.customer;

  const handlePaymentChange = (event) => {
    //CHECAR AQUI O IFOOD

    setSelectedValue(event.target.value);
    if (event.target.value === "card") {
      handleOpenCardDialog();
    }
    if (event.target.value === "cash") {
      handleOpenCashDialog();
    }
    if (event.target.value === "pix") {
      handleOpenPixDialog();
    }
  };

  const handleIfoodPaymentChange = (event) => {
    setSelectedValue("ifood");
    setSelectedIfoodValue(event.target.value);
    if (event.target.value === "card") {
      //handleOpenCardDialog();
    }
  };

  const testAddress = (q) => {
    const teste = pointByAddress(q).then((res) => {
      //console.log(res);
    });
    ////console.log(teste);
  };

  /* DIALOG FUNCTIONS */

  const handleOpenAddressDialog = () => {
    setAddressDialog(true);
  };

  const handleCloseAddressDialog = () => {
    setAddressDialog(false);
  };

  const handleOpenEditCustomerDialog = () => {
    setEditCustomerDialog(true);
  };

  const handleCloseEditCustomerDialog = () => {
    setEditCustomerDialog(false);
  };

  const handleOpenUserAddressesDialog = () => {
    ////console.log("state branch", state.branch);
    setUserAddressesDialog(true);
  };

  const handleCloseUserAddressesDialog = () => {
    setUserAddressesDialog(false);
  };

  const handleOpenCardDialog = () => {
    setCardDialog(true);
  };

  const handleCloseCardDialog = () => {
    setCardDialog(false);
  };

  const handleOpenCashDialog = () => {
    setCashDialog(true);
  };

  const handleOpenPixDialog = () => {
    setPixDialog(true);
  };

  const handleCloseCashDialog = () => {
    setCashDialog(false);
  };

  const handleClosePixDialog = () => {
    setPixDialog(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  /* DIALOG FUNCTIONS */

  const [openSuccess, setOpenSuccess] = useState(false);

  const handleBeforeOrder = () => {
    let buttonEnabled = true;

    if (state.consumeType === 1) {
      if (
        !selectedValue ||
        selectedAddress === null ||
        disabledDelivery ||
        state.cart.length === 0
      ) {
        buttonEnabled = false;
      }
    } else {
      if (!selectedValue || state.cart.length === 0) {
        buttonEnabled = false;
      }
    }

    return buttonEnabled;
  };

  const testRedirect = () => {
    navigate(`/${restaurantName}/sucesso/`);
  };

  useEffect(() => {
    registerSessionActivity(
      {
        type: "CHECKOUT_PAGE",
        branch: state.branch._id,
        cart: state.cart,
      },
      state.branch
    );
  }, []);

  const handleSendOrder = () => {
    sendOrder()
      .then((order) => {
        ////console.log("order", order);

        registerSessionActivity(
          {
            type: "ORDER_FINISHED",
            branch: state.branch._id,
            orderId: order._id,
            orderSeq: order.seq,
            cart: state.cart,
          },
          state.branch
        );

        setLastOrder(order);
        handleOpenSuccess();
        setTimeout(() => {
          handleCloseSucess();
          updateCart([]); //CLEAN THE SHOPPING CART
          navigate(`/${restaurantName}/pedido/${order._id}`); //USE THE REDIRECT
        }, 1000);
      })
      .catch((e) => {
        ////console.log(e);
      });
  };

  const handleOpenSuccess = () => {
    setOpenSuccess(true);
  };

  const handleCloseSucess = () => {
    setOpenSuccess(false);
  };
  // 2

  useEffect(() => {
    //getCurrentPosition();
    handleOpen();
  }, []);

  useEffect(() => {
    if (state.consumeType === 1) {
      handleDeliveryFees(); //JOGAR O ADDRESS NO STATE
    }
  }, [selectedAddress]);

  useEffect(() => {
    if (state.appliedCoupon) {
      if (state.appliedCoupon && state.appliedCoupon.reward.type === 2) {
        setDeliveryFee(0);
      } else {
        setDeliveryFee(state.deliveryInfo && state.deliveryInfo.price);
      }
    }
  }, [state.appliedCoupon]);

  useEffect(() => {
    ////console.log("selectedIfoodValue", selectedIfoodValue);

    const payment =
      state.deliveryInfo &&
      state.deliveryInfo.quote &&
      state.deliveryInfo.quote.paymentMethods.filter(
        (p) => p.id === selectedIfoodValue
      )[0];

    if (payment && payment.method === "CASH") {
      ////console.log("dinheiro ifood", payment);
      handleOpenCashDialog();
    }
  }, [selectedIfoodValue]);

  const handleDeliveryFees = () => {
    ////console.log("handleFee");
    //const preferedType = state.branch.deliveryType; //PUXAR ISSO DA BRANCH

    //neighborhoodList
    //fixedFee
    //mapArea

    //if modality

    const preferedDeliveryType = state.branch.deliveryType;

    ////console.log("deliveryType", preferedDeliveryType);

    if (preferedDeliveryType === "neighborhoodList") {
      const neighborhoodList = state.branch.deliveryNeighborhoods;

      if (selectedAddress !== null) {
        const foundNeighborhood = neighborhoodList.filter(
          (n) =>
            n.name.toLowerCase() === selectedAddress.neighborhood.toLowerCase()
        );

        ////console.log("found neighborhood", foundNeighborhood);

        if (foundNeighborhood.length) {
          setDeliveryFee(foundNeighborhood[0].price);
          setDeliveryFeeTime(foundNeighborhood[0].time);
          disableDelivery(false);
        } else {
          setDeliveryFee(0);
          setDeliveryFeeTime(0);
          disableDelivery(true);
        }
      }
    }

    if (preferedDeliveryType === "fixedFee") {
      setDeliveryFee(state.branch.deliveryFixedFee);
      disableDelivery(false);
    }

    if (preferedDeliveryType === "areaFee") {
      ////AREAS DO MAPA - ACHAR TODAS AS INTERSEÇÕES E CALCULAR A MENOR TAXA
      disableDelivery(false);

      /* const price = "6,50";
      //console.log("parseFloat", parseFloat(price)); */

      ////CALCULAR AREA E PREÇO
      const userAddress = selectedAddress;
      ////console.log("userAddress", userAddress);

      if (userAddress && userAddress.coordinates) {
        const userCoordinates = userAddress.coordinates;

        let availableDelivery = [];

        state.branches.map((branch) => {
          let insideArea = false;
          let matchingExcludingAreas = 0;

          //////
          let prices = [];
          let deliveryTime = [];
          //////

          if (branch.deliveryAreas) {
            branch.deliveryAreas.features.map((feature) => {
              ////console.log("FEATURE", feature);
              const polygon = feature.geometry;
              const userPosition = [userCoordinates.lon, userCoordinates.lat];
              if (
                feature.geometry &&
                feature.geometry.type &&
                feature.geometry.type === "Point"
              ) {
                if (feature.properties.radius) {
                  const coordinates = [
                    parseFloat(userCoordinates.lat),
                    parseFloat(userCoordinates.lon),
                    feature.geometry.coordinates[1],
                    feature.geometry.coordinates[0],
                  ];
                  const lat1 = coordinates[0];
                  const lon1 = coordinates[1];
                  const lat2 = coordinates[2];
                  const lon2 = coordinates[3];

                  const distance = calculateDistance(lat1, lon1, lat2, lon2);

                  const radius = feature.properties.radius / 1000;

                  /* //console.log(
                    "feature.properties.excludingArea",
                    feature.properties.excludingArea
                  ); */

                  if (distance <= radius) {
                    ////console.log("Distance in kilometers:", distance);
                    ////console.log("Radius:", feature.properties.radius / 1000);
                    ////console.log("menor que o raio", feature);

                    prices.push(parseFloat(feature.properties.price));
                    deliveryTime.push(
                      parseFloat(feature.properties.deliveryTime)
                    );

                    insideArea = true;
                  } else {
                    ////console.log("Distance in kilometers:", distance);
                    ////console.log("Radius:", feature.properties.radius / 1000);
                    ////console.log("maior que o raio", feature);
                  }

                  const smallestValue = Math.min(...prices);
                  const smallestDeliveryTime = Math.min(...deliveryTime);

                  branch.deliveryInfo = {
                    price: smallestValue,
                    deliveryTime: smallestDeliveryTime,
                  };

                  /* //console.log("deliveryInfo", {
                    price: smallestValue,
                    deliveryTime: smallestDeliveryTime,
                  }); */
                }
              } else {
                if (isPointInsidePolygon(userPosition, polygon)) {
                  ////console.log("is inside");
                  if (
                    feature.properties.excludingArea !== null &&
                    feature.properties.excludingArea !== false
                  ) {
                    insideArea = true;
                    branch.deliveryInfo = {
                      price: feature.properties.price,
                      deliveryTime: feature.properties.deliveryTime,
                    };
                  }
                } else {
                  insideArea = false;
                  ////console.log("is outside");
                }
              }
            });
            if (insideArea && matchingExcludingAreas === 0) {
              availableDelivery.push(branch);
              ////console.log("prices array", prices);
            }
            ////console.log("matchingExcludingAreas", matchingExcludingAreas);
          }
        });

        ////console.log("availableDelivery", availableDelivery);

        if (
          availableDelivery.filter((b) => b._id === state.branch._id).length
        ) {
          ////console.log("essa branch está nas eligibles");

          const foundBranch = availableDelivery.filter(
            (b) => b._id === state.branch._id
          )[0];
          ////console.log("foundBranch", foundBranch);

          //SETAR TAXAS - VERIFICAR AQUI SE É IFOOD

          if (foundBranch.deliveryMethod === "ifood") {
            ////console.log("do not set a new fee, make a quote", foundBranch.name);
          } else {
            setDeliveryFee(foundBranch.deliveryInfo.price);
            setDeliveryFeeTime(foundBranch.deliveryInfo.deliveryTime);
          }
          //SETAR TAXAS

          disableDelivery(false);
        } else {
          ////console.log("essa branch NÃO está nas eligibles");
          disableDelivery(true);
          setDeliveryFee(0);
          setDeliveryFeeTime(0);
        }
      } else {
        ////console.log("no coordinates");
      }
      ////CALCULAR AREA E PREÇO
    }
  };

  const checkDistances = () => {
    ////console.log("check distances");

    //SAMPLE POLYGON
    //GEOMETRY.POLYGON
    const samplePolygon = {
      coordinates: [
        [
          [-38.592124, -3.766582],
          [-38.550223, -3.72804],
          [-38.499734, -3.781313],
          [-38.547475, -3.810431],
          [-38.592124, -3.766582],
        ],
      ],
    };

    //CESAR ROSSAS 43 - MONTESE (POSICAO ATUAL)
    const deliveryCoordinates = [-38.55306, -3.76844];
    //BARBOSA DE FREITAS 1455 - ALDEOTA (POSICAO INICIAL)
    const deliveryOrigin = [-38.4992, -3.74249];

    ///CHECAR O RAIO

    /* //console.log(
      "haversine result",
      calculateHaversineDistance(
        deliveryCoordinates[0],
        deliveryCoordinates[1],
        deliveryOrigin[0],
        deliveryOrigin[1]
      )
    );
    //console.log(
      "polygon result",
      isPointInsidePolygon(deliveryCoordinates, samplePolygon)
    ); */
  };

  return (
    <>
      <Snackbar
        open={disabledDelivery}
        autoHideDuration={4000}
        anchorOrigin={{
          horizontal: "left",
          vertical: "bottom",
        }}
      >
        <Alert
          iconMapping={{
            info: <SentimentDissatisfied fontSize="inherit" />,
          }}
          severity="error"
        >
          <strong>Área de entrega não atendida</strong>
          <br />
          Por favor, considere solicitar retirada.
        </Alert>
      </Snackbar>
      <SignInEdit
        editCustomerDialog={editCustomerDialog}
        handleCloseEditCustomerDialog={handleCloseEditCustomerDialog}
      />
      {/* NEW ADDRESS DIALOG */}
      <AddressDialog
        openAddressDialog={openAddressDialog}
        handleCloseAddressDialog={handleCloseAddressDialog}
        handleOpenAddressDialog={handleOpenAddressDialog}
        currentCustomer={currentCustomer}
        setSelectedAddress={setSelectedAddress}
        handleCloseUserAddressesDialog={handleCloseUserAddressesDialog}
      />

      {/* EXISTING CUSTOMER ADDRESSES */}
      <UserAddresses
        openAddressDialog={openAddressDialog}
        openUserAddressesDialog={openUserAddressesDialog}
        handleOpenAddressDialog={handleOpenAddressDialog}
        handleCloseUserAddressesDialog={handleCloseUserAddressesDialog}
        currentCustomer={currentCustomer}
        setSelectedAddress={setSelectedAddress}
      />
      {/* SELECT CARD BRAND */}
      <CardDialog
        branch={state.branch}
        openCardDialog={openCardDialog}
        handleCloseCardDialog={handleCloseCardDialog}
        setCardBrand={setCardBrand}
      />
      {/* REQUEST THE CHANGE AMOUNT FOR CASH PAYMENT */}
      <CashDialog
        openCashDialog={openCashDialog}
        handleCloseCashDialog={handleCloseCashDialog}
        setCashChange={setCashChange}
        cart={state.cart}
        cashChange={cashChange}
        deliveryFee={deliveryFee}
      />
      <PixDialog
        branch={state.branch}
        openPixDialog={openPixDialog}
        handleClosePixDialog={handleClosePixDialog}
        setCashChange={setCashChange}
      />
      {/* SENDING ORDER DIALOG */}
      <SendingOrderDialog
        openSuccess={openSuccess}
        handleCloseSucess={handleCloseSucess}
      />
      {/* CHECK USER INFO BEFORE DISPLAYING ORDER OVERVIEW */}
      <CustomerConfirmationDialog
        open={open}
        handleClose={handleClose}
        currentCustomer={currentCustomer}
        handleOpenEditCustomerDialog={handleOpenEditCustomerDialog}
      />
      <Box
        sx={{
          paddingLeft: {
            xs: "25px !important",
            md: "0px !important",
          },
          paddingRight: {
            xs: "25px !important",
            md: "0px !important",
          },
        }}
      >
        <Grid container spacing={0}>
          {/* ------------------------- row 1 ------------------------- */}
          <Grid item xs={12} lg={12} style={{ marginTop: "75px" }}>
            <Typography variant="h3" style={{ marginBottom: "15px" }}>
              Finalizar Pedido
            </Typography>
            <Divider />
          </Grid>
          <OrderHeader
            currentCustomer={currentCustomer}
            handleOpenEditCustomerDialog={handleOpenEditCustomerDialog}
            handleCloseEditCustomerDialog={handleCloseEditCustomerDialog}
            cart={state.cart}
            deliveryFeeTime={deliveryFeeTime}
          />
          {state.consumeType === 1 && (
            <DeliveryInfoOverview
              deliveryFee={deliveryFee}
              selectedAddress={selectedAddress}
              handleOpenUserAddressesDialog={handleOpenUserAddressesDialog}
            />
          )}
          <PaymentMethods
            branch={state.branch}
            selectedValue={selectedValue}
            selectedIfoodValue={selectedIfoodValue}
            handlePaymentChange={handlePaymentChange}
            handleIfoodPaymentChange={handleIfoodPaymentChange}
            translatePayment={translatePayment}
          />
          <CouponInfo />
          <GeneralNotes setGeneralNotes={setGeneralNotes} />
          <TaxPayerInfo taxPayerId={taxPayerId} setTaxPayerId={setTaxPayerId} />

          <CheckoutFooter cart={state.cart} deliveryFee={deliveryFee} />
          {/* <Box>
            <Button
              onClick={() =>
                testAddress("Rua Cesar Rossas 43 - Montese - Fortaleza")
              }
            >
              CHECAR POSICAO
            </Button>
          </Box>
          <Box>
            <Button onClick={() => checkDistances()}>CHECAR DISTANCIA</Button>
          </Box>
          <Box>
            <Button onClick={() => //console.log(state)}>CHECAR STATE</Button>
          </Box>
          <Box>
            <Button onClick={() => calculatePreparationTime(state.cart, 15)}>
              CHECAR PREPARATION
            </Button>
          </Box> */}
          <Box style={{ width: "100%" }}>
            {state.branch.closed || state.closed ? (
              <Button disabled fullWidth variant="contained">
                LOJA FECHADA NO MOMENTO
              </Button>
            ) : (
              <Button
                disabled={!handleBeforeOrder()}
                onClick={() => handleSendOrder(state.cart)}
                fullWidth
                variant="contained"
              >
                {!handleBeforeOrder() ? (
                  <span>Preencha todas as informações ou verifique a área</span>
                ) : (
                  <span>Concluir Pedido</span>
                )}
              </Button>
            )}
          </Box>
        </Grid>
      </Box>
    </>
  );
};

export default CheckOut;
