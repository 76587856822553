import React, { useState, useEffect } from "react";
import {
  Alert,
  Grid,
  Box,
  Typography,
  IconButton,
  Button,
  Stack,
  TextField,
} from "@mui/material";
import currency from "currency-formatter";
import { useApp } from "../../context/appContext";
import { ArrowForwardIosRounded } from "@mui/icons-material";
import { RemoveCircle } from "@mui/icons-material";
import { Delete } from "@mui/icons-material";

const CouponInfo = () => {
  const { state, applyCoupon } = useApp();

  const [couponCode, setCouponCode] = useState("");

  const checkCoupon = () => {
    const coupons = state.coupons.filter(
      (c) => c.rules.couponCode.toLowerCase() === couponCode.toLowerCase()
    );

    //FAZER A CONSULTA SE O CUPOM É ENTREGA AQUI

    if (coupons.length !== 0) {
      //console.log("cupom válido", couponCode);
      applyCoupon(coupons[0]);
    } else {
      //console.log("cupom inválido", couponCode);
      applyCoupon(null);
    }
  };

  const removeCoupon = () => {
    applyCoupon(null);
    setCouponCode("");
  };

  return (
    <Grid item xs={12} lg={12} style={{ marginTop: "25px" }}>
      <>
        <Box
          style={{
            backgroundColor: "#ecf7ff",
            padding: "15px",
            borderRadius: "5px",
          }}
        >
          <Typography
            variant="h6"
            sx={{
              fontWeight: "600",
            }}
          >
            Possui um cupom de desconto?
          </Typography>
        </Box>
        <Box display="flex" alignItems="center">
          <Grid container>
            <Grid item sm={10} md={11}>
              {!state.appliedCoupon ? (
                <TextField
                  disabled={state.appliedCoupon}
                  onChange={(e) => setCouponCode(e.target.value)}
                  autoComplete="off"
                  defaultValue={
                    state.appliedCoupon
                      ? state.appliedCoupon.rules.couponCode
                      : couponCode
                  }
                  style={{ marginTop: "25px" }}
                  id="default-textarea"
                  label="Digite o código do cupom"
                  variant="outlined"
                  fullWidth
                  sx={{
                    mb: 2,
                  }}
                />
              ) : (
                <Alert
                  iconMapping={{
                    info: <Delete fontSize="inherit" />,
                  }}
                  severity="success"
                  sx={{
                    marginTop: "25px",
                    marginBottom: "25px",
                  }}
                >
                  <strong>
                    {state.appliedCoupon && state.appliedCoupon.title}
                  </strong>
                  <br />
                  {state.appliedCoupon && state.appliedCoupon.description}
                </Alert>
              )}
            </Grid>
            <Grid
              item
              sm={2}
              md={1}
              sx={{ alignContent: "center", textAlign: "center" }}
            >
              {state.appliedCoupon ? (
                <IconButton
                  onClick={() => removeCoupon()}
                  variant="contained"
                  color="primary"
                  sx={{
                    mr: 1,
                  }}
                >
                  <RemoveCircle />
                </IconButton>
              ) : (
                <IconButton
                  onClick={() => checkCoupon()}
                  variant="contained"
                  color="primary"
                  sx={{
                    mr: 1,
                  }}
                >
                  <ArrowForwardIosRounded />
                </IconButton>
              )}
            </Grid>
          </Grid>
        </Box>
        {/* <Box
          style={{
            marginBottom: "15px",
            border: "1px solid #ecf7ff",
            padding: "15px",
          }}
        ></Box> */}
      </>
    </Grid>
  );
};
export default CouponInfo;
