import React from "react";
import { useState, useEffect, useRef } from "react";
import {
  Grid,
  Box,
  Typography,
  Button,
  TextField,
  Snackbar,
  Alert,
  Dialog,
} from "@mui/material";
import { SentimentSatisfied } from "@mui/icons-material";
import { Link, NavLink, useParams } from "react-router-dom";
import { useApp } from "../../context/appContext";
import locations from "../../services/locations";
import api from "../../services/rest";
import InputMask from "react-input-mask";
import Lottie from "lottie-react";
import emojiAnimation from "./emoji.json";
import axios from "axios";
import moment from "moment";

const SignInEdit = ({
  isExternal,
  externalData,
  editCustomerDialog,
  handleCloseEditCustomerDialog,
  fromHeader,
  redirectBack,
}) => {
  // 2

  const { restaurantName } = useParams();

  const { getData, setCustomer, state, postData, patchData } = useApp();

  const [snack, setSnack] = useState(false);

  const [number, setNumber] = useState("");
  const [name, setName] = useState(
    (externalData && externalData.name) ||
      (externalData && externalData.externalName) ||
      ""
  );

  const inputRef = useRef();

  const createCustomer = async () => {
    const user = {
      name: name,
      phone: removeFormating(number),
      customerOf: state.company._id,
      addresses: [],
    };
    getData("manager/customers", {
      phone: removeFormating(number),
      customerOf: state.company._id,
    }).then((c) => {
      if (c.data.length !== 0) {
        const foundCustomer = c.data[0];
        setCustomer(foundCustomer);
        setName(foundCustomer.name);
        handleCloseEditCustomerDialog();

        if (fromHeader) {
          redirectBack();
        }
      } else {
        //ENVIAR PRO EXTERNAL CUSTOMER - LA NO AFTER ELE VAI CRIAR

        if (isExternal) {
          //EXTERNAL

          const externalUser = {
            name: name,
            phone: removeFormating(number),
            company: state.company._id,
            companySlug: state.company.slug,
            couponId: externalData.couponId,
            source: externalData.source || externalData.externalSource,
            sourceCustomerId:
              externalData.sourceCustomerId ||
              externalData.externalSourceCustomer,
            sourceOrderId:
              externalData.sourceOrderId || externalData.externalSourceOrder,
            externalId: externalData.externalId || null,
          };

          //console.log("externalUser", externalUser);

          postData(locations.externalCustomers, externalUser).then((res) => {
            //console.log("external customer created", res);
            handleCloseEditCustomerDialog();
            setCustomer(res);

            //PATCH INTERNAL CUSTOMER

            patchData(locations.externalReviews, externalData._id, {
              localCustomer: res._id,
              customerPhone: removeFormating(number),
            }).then((r) => {
              console.log("userid added to the review", r);
            });

            if (fromHeader) {
              redirectBack();
            }

            //navigate(`/${restaurantName}/finalizar`);
          });
        } else {
          postData(locations.customers, user).then((res) => {
            //console.log("new customer", res);

            axios.post(
              "https://n8n.dnaservico.com.br/webhook/cardapio_cadastro",
              {
                name: res.name,
                phone: res.phone,
                createdAt: res.createdAt,
                signedInAt: moment().toISOString(),
                origin: "cardapio-web",
                companyId: state.company._id,
                companyName: state.company.name,
                newClient: true,
              }
            );

            handleCloseEditCustomerDialog();
            setCustomer(res);

            if (fromHeader) {
              redirectBack();
            }

            //navigate(`/${restaurantName}/finalizar`);
          });
        }
      }
    });
  };

  const handleCreateCustomer = () => {
    createCustomer();
  };

  const getCustomerData = async () => {
    //console.log("getting customer data");

    getData("manager/customers", {
      phone: removeFormating(number),
      customerOf: state.company._id, //CHECAR A COMPANY AQUI
    })
      .then((c) => {
        //console.log("customerData", c);

        if (c.data.length > 0) {
          const foundCustomer = c.data[0];
          setCustomer(foundCustomer);
          setName(foundCustomer.name);
          setSnack(true);
          axios.post(
            "https://n8n.dnaservico.com.br/webhook/cardapio_cadastro",
            {
              name: foundCustomer.name,
              phone: foundCustomer.phone,
              createdAt: foundCustomer.createdAt,
              signedInAt: moment().toISOString(),
              origin: "cardapio-web",
              companyId: state.company._id,
              companyName: state.company.name,
              newClient: false,
            }
          );
        } else {
          //DO NOTHING - LETS CREATE A NEW USER LATER

          ////console.log("user not found");

          const user = {
            name: name,
            phone: removeFormating(number),
            customerOf: "",
            addresses: [],
          };
          ////console.log("temp user", user);
          setCustomer(user);
        }
      })
      .catch((e) => {
        //console.log("customer error");
        //console.log(e);
      });
  };

  /* useEffect(() => {
    getCustomerData();
    ///VERIFICAR AQUI E USAR O MESMO ESQUEMA DA BUSCA NA API PRA EVITAR LOOP E TIMEOUT
    if (number === "85981335195") {
      setName("Airton Mendonca");
      setSnack(true);
      inputRef.current.focus();
    } else {
      setName("");
    }
  }, [number]); */

  const removeFormating = (number) => {
    return number.replace(/\D/g, "");
  };

  useEffect(() => {
    ////console.log("number", number);
    ////console.log("unformatted number", removeFormating(number));

    const delayDebounceFn = setTimeout(() => {
      console.log("number", number);
      if (removeFormating(number) !== "") {
        getCustomerData();
      }
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [number]);

  return (
    <Dialog
      onClose={() => {
        handleCloseEditCustomerDialog();
      }}
      open={editCustomerDialog}
    >
      <Box sx={{ padding: "25px", width: { md: "400px" } }}>
        <Snackbar
          open={snack}
          autoHideDuration={4000}
          anchorOrigin={{
            horizontal: "left",
            vertical: "bottom",
          }}
          onClose={() => setSnack(!snack)}
        >
          <Alert
            iconMapping={{
              info: <SentimentSatisfied fontSize="inherit" />,
            }}
            severity="info"
          >
            <strong>Bem-vindo de volta, {name}</strong>
            <br />
            Bom te ver aqui novamente.
          </Alert>
        </Snackbar>
        <Grid container spacing={0}>
          {/* ------------------------- row 1 ------------------------- */}
          <Grid
            item
            xs={12}
            lg={12}
            style={{
              marginTop: "15px",
            }}
          >
            {isExternal ? (
              <Box
                sx={{
                  textAlign: "center",
                }}
              >
                <Lottie
                  animationData={emojiAnimation}
                  loop
                  style={{
                    width: "100px",
                    margin: "auto",
                  }}
                />
                {externalData &&
                externalData.name &&
                externalData.name !== "" ? (
                  <Typography variant="h3">
                    Olá, {externalData.name}!
                  </Typography>
                ) : (
                  <Typography variant="h3">Olá! Tudo bem?</Typography>
                )}
                <Typography variant="h5">
                  Ficamos felizes de te ver por aqui!
                  <br />
                  Informe seu WhatsApp para ver nossos itens e ofertas
                  imperdíveis! 🍔😋
                </Typography>
              </Box>
            ) : (
              <Typography variant="h3">Identifique-se</Typography>
            )}
          </Grid>
          <Grid
            item
            xs={12}
            lg={12}
            style={{
              marginTop: "50px",
            }}
          >
            <Box>
              <Typography>
                <strong>Seu número de WhatsApp</strong>
              </Typography>
              {/* <TextField
                autoComplete="off"
                style={{ marginTop: "25px" }}
                id="default-textarea"
                label="Seu número de WhatsApp"
                variant="outlined"
                fullWidth
                onChange={(e) => setNumber(e.target.value)}
                sx={{
                  mb: 2,
                }}
              /> */}
              <InputMask
                mask="(99) 99999 9999"
                value={number}
                disabled={false}
                maskChar=" "
                onChange={(e) => setNumber(e.target.value)}
              >
                {() => (
                  <TextField
                    autoComplete="off"
                    style={{ marginTop: "25px" }}
                    id="default-textarea"
                    label="Seu número de WhatsApp"
                    variant="outlined"
                    fullWidth
                    sx={{
                      mb: 2,
                    }}
                  />
                )}
              </InputMask>
            </Box>
            <Box>
              <Typography>
                <strong>Seu nome completo</strong>
              </Typography>
              <TextField
                style={{
                  marginTop: "25px",
                }}
                inputRef={inputRef}
                id="default-textarea"
                label="Seu nome"
                variant="outlined"
                defaultValue={name}
                value={name}
                onChange={(e) => setName(e.target.value)}
                fullWidth
                sx={{
                  mb: 2,
                }}
              />
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            lg={12}
            style={{
              marginTop: "50px",
            }}
          >
            {!isExternal && (
              <Button
                onClick={() => handleCloseEditCustomerDialog()}
                variant="outlined"
                style={{
                  marginRight: "15px",
                }}
              >
                Voltar
              </Button>
            )}
            {name !== "" && number !== "" ? (
              <Button
                onClick={() => createCustomer()}
                variant="contained"
                fullWidth={isExternal}
              >
                Avançar
              </Button>
            ) : (
              <Button variant="contained" fullWidth={isExternal} disabled>
                Preencha os obrigatórios
              </Button>
            )}
          </Grid>
          {/* ------------------------- row 2 ------------------------- */}
          {/* <Grid item xs={12} lg={4}>
          <DailyActivities />
        </Grid>
        <Grid item xs={12} lg={8}>
          <ProductPerformance />
        </Grid> */}
          {/* ------------------------- row 3 ------------------------- */}
        </Grid>
      </Box>
    </Dialog>
  );
};

export default SignInEdit;
