import React, { useState, useEffect } from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Box,
  Typography,
  Divider,
  Button,
  TextField,
  Dialog,
} from "@mui/material";
import { useApp } from "../../../context/appContext";
import AddIcon from "@mui/icons-material/Add";
import { Link, NavLink } from "react-router-dom";
import "../../../global.css";
import { pointByAddress, addressByPoint } from "../../../utils/geoLocation";
import FmdGoodIcon from "@mui/icons-material/FmdGood";
import brazilianStates from "../../../utils/brazilianStates";

const AddressDialog = ({
  handleCloseAddressDialog,
  openAddressDialog,
  currentCustomer,
  setSelectedAddress,
  handleCloseUserAddressesDialog,
}) => {
  const { setCustomer } = useApp();

  const [selectedNeighborhood, setSelectedNeighborhood] = useState(null);

  const [addressUF, setUF] = useState("Ceará");

  const selectBairro = (bairro) => {
    setSelectedNeighborhood(bairro);
  };

  const handleUFChange = (e) => {
    setUF(e);
  };

  const [formInput, setFormInput] = useState({
    street: "",
    number: "",
    neighborhood: "",
    complement: "",
    reference: "",
    city: "",
    state: addressUF,
    postalCode: "",
  });

  const handleFormInput = (e) => {
    setFormInput({
      ...formInput,
      [e.target.name]: e.target.value,
    });
  };

  const saveAddress = async () => {
    let address = {
      street: formInput.street,
      number: formInput.number,
      complement: formInput.complement,
      neighborhood: formInput.neighborhood,
      city: formInput.city,
      state: addressUF,
      postalCode: formInput.postalCode,
    };

    let newCustomer = currentCustomer;

    //ADICIONAR UM LOADING AQIO ENQUANTO CAPTURA AS CORDENADAS

    /* //console.log(
      `Request: ${address.street} ${address.number}, ${address.neighborhood}`
    ); */

    await pointByAddress(
      `${address.street} ${address.number}, ${address.neighborhood}, ${address.city}, ${address.state}`
    )
      .then((res) => {
        ////console.log("position", res);
        address.coordinates = res;
      })
      .catch((e) => {
        ////console.log(e);
      });

    address.reference = formInput.reference;

    newCustomer.addresses.push(address);

    ////console.log(newCustomer);
    setCustomer(newCustomer);

    setSelectedAddress(address);

    handleCloseAddressDialog();
    handleCloseUserAddressesDialog();
  };

  const handlePosition = () => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        function (position) {
          ////console.log(position.coords.latitude);
          ////console.log(position.coords.longitude);
          addressByPoint(
            position.coords.latitude,
            position.coords.longitude
          ).then((res) => {
            setUF(res.address.state);
            ////console.log("res do reverse geocoding", res.address);
            setFormInput({
              street: res.address.road,
              number: res.address.house_number,
              neighborhood: res.address.suburb,
              complement: "",
              reference: "",
              city: res.address.city,
              state: res.address.state,
              postalCode: res.address.postcode,
            });
          });
        },
        function (error) {
          console.error("Error Code = " + error.code + " - " + error.message);
        }
      );
    }
  };

  return (
    <Dialog
      onClose={() => {
        handleCloseAddressDialog();
        setSelectedNeighborhood(null);
      }}
      open={openAddressDialog}
    >
      <Box sx={{ padding: "25px", width: { md: "400px" } }}>
        <div className="wrapperData" style={{ marginBottom: "50px" }}>
          <Box
            lg={{
              textAlign: "center",
            }}
          >
            <Typography
              variant="h2"
              lg={{
                width: "100%",
                textAlign: "center",
              }}
            >
              Informe seu endereço
            </Typography>
            <Typography
              variant="h6"
              lg={{
                width: "100%",
                textAlign: "center",
                marginBottom: "25px",
              }}
            >
              Todos os campos são obrigatórios com exceção ao{" "}
              <strong>complemento</strong> (caso não possua)
            </Typography>
          </Box>
          <Box
            sx={{
              marginTop: "15px",
            }}
          >
            <Button
              startIcon={<FmdGoodIcon />}
              onClick={() => handlePosition()}
            >
              Localização atual
            </Button>
          </Box>
          <Box>
            <TextField
              style={{ marginTop: "5px" }}
              id="default-textarea"
              value={formInput.street}
              autoComplete="off"
              label="Rua"
              name="street"
              onChange={(e) => handleFormInput(e)}
              variant="outlined"
              fullWidth
              sx={{
                mb: 2,
              }}
            />
          </Box>
          <Box>
            <TextField
              style={{ marginTop: "5px" }}
              id="default-textarea"
              autoComplete="off"
              value={formInput.number}
              label="Número da casa/edifícil"
              name="number"
              onChange={(e) => handleFormInput(e)}
              variant="outlined"
              fullWidth
              sx={{
                mb: 2,
              }}
            />
          </Box>
          <Box>
            <TextField
              style={{ marginTop: "5px" }}
              id="default-textarea"
              autoComplete="off"
              label="Complemento"
              name="complement"
              onChange={(e) => handleFormInput(e)}
              variant="outlined"
              fullWidth
              sx={{
                mb: 2,
              }}
            />
          </Box>
          <Box>
            <TextField
              style={{ marginTop: "5px" }}
              id="default-textarea"
              autoComplete="off"
              label="Ponto de referência"
              name="reference"
              onChange={(e) => handleFormInput(e)}
              variant="outlined"
              fullWidth
              sx={{
                mb: 2,
              }}
            />
          </Box>
          <Box>
            <TextField
              style={{ marginTop: "5px" }}
              id="default-textarea"
              autoComplete="off"
              label="Bairro"
              value={formInput.neighborhood}
              variant="outlined"
              name="neighborhood"
              onChange={(e) => handleFormInput(e)}
              fullWidth
              sx={{
                mb: 2,
              }}
            />
          </Box>
          <Box>
            <TextField
              id="default-textarea"
              autoComplete="off"
              label="CEP"
              value={formInput.postalCode}
              variant="outlined"
              name="postalCode"
              onChange={(e) => handleFormInput(e)}
              fullWidth
              sx={{
                mb: 2,
              }}
            />
          </Box>
          <Box>
            <TextField
              id="default-textarea"
              autoComplete="off"
              label="Cidade"
              variant="outlined"
              value={formInput.city}
              name="city"
              onChange={(e) => handleFormInput(e)}
              fullWidth
              sx={{
                mb: 2,
              }}
            />
          </Box>
          <Box>
            {/* STATE SELECT BY LIST */}
            <FormControl
              fullWidth
              style={{ marginTop: "5px", marginBottomm: "15px" }}
            >
              <InputLabel id="demo-simple-select-label">Estado</InputLabel>
              <Select
                onChange={(e) => handleUFChange(e.target.value)}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Estado"
                defaultValue={`Ceará`}
                value={addressUF}
              >
                {brazilianStates.map((uf) => (
                  <MenuItem value={uf}>
                    <Typography>{uf}</Typography>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            {/* <TextField
              style={{ marginTop: "5px" }}
              id="default-textarea"
              autoComplete="off"
              label="Estado"
              value={formInput.state}
              variant="outlined"
              name="state"
              onChange={(e) => handleFormInput(e)}
              fullWidth
              sx={{
                mb: 2,
              }}
            /> */}
          </Box>
          {/* <Box
            style={{ width: "100%", marginBottom: "15px", marginTop: "25px" }}
          >
            <Button
              onClick={() => setSelectedNeighborhood(null)}
              fullWidth
              variant="outlined"
            >
              Escolher outro bairro
            </Button>
          </Box> */}
          <Box style={{ width: "100%", marginTop: "15px" }}>
            <Button onClick={() => saveAddress()} fullWidth variant="contained">
              Salvar Endereço
            </Button>
          </Box>
        </div>
      </Box>
    </Dialog>
  );
};
export default AddressDialog;
