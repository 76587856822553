import React, { useEffect, useState } from "react";
import {
  Grid,
  Box,
  Typography,
  ButtonGroup,
  Dialog,
  Button,
  TextField,
  Rating,
} from "@mui/material";
import { Link, NavLink, useParams, useSearchParams } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { useApp } from "../../context/appContext";
import OrderViewHeader from "./OrderViewHeader";
import OrderItemsView from "./OrderItems";
import OrderViewFooter from "./OrderFooter";
import OrderDeliveryAddress from "./OrderDeliveryAddress";
import OrderTimeline from "./OrderTimeline";
import OrderTakeAwayAddress from "./OrderTakeAwayAddress";
import locations from "../../services/locations";
import OrderTimelineMobile from "./OrderTimelineMobile";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import StoreLogoHeader from "../../layouts/FullLayout/Sidebar/storeLogoHeader";
import StoreLogo from "../../layouts/FullLayout/Sidebar/storeLogo";
import moment from "moment/moment";
import Spinner from "../Spinner/Spinner";
import SignInEdit from "../SignIn/SignInEdit";

const ExternalReviewPage = () => {
  // 2
  const {
    state,
    getData,
    getSingleData,
    postData,
    populateCompany,
    setAddress,
    populateData,
    patchData,
    setConsumeType,
    setCustomer,
  } = useApp();

  const { restaurantName, orderId, code } = useParams();

  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const [params] = useSearchParams();

  const [orderBranch, setOrderBranch] = useState(null);

  useEffect(() => {
    console.log("code", code);
  }, []);

  ///RATING
  const [ratingItems, setRatingItems] = useState(null);
  const [ratingQuality, setRatingQuality] = useState(null);
  const [ratingDificulty, setRatingDificulty] = useState(null);
  const [ratingDeliveryTime, setRatingDeliveryTime] = useState(null);
  const [ratingPrice, setRatingPrice] = useState(null);
  const [ratingSupport, setRatingSupport] = useState(null);

  //RATING SUGGESTIONS
  const [ratingSuggestionItems, setRatingSuggestionItems] = useState(null);
  const [ratingSuggestionQuality, setRatingSuggestionQuality] = useState(null);
  const [ratingSuggestionDificulty, setRatingSuggestionDificulty] =
    useState(null);
  const [ratingSuggestionDeliveryTime, setRatingSuggestionDeliveryTime] =
    useState(null);
  const [ratingSuggestionPrice, setRatingSuggestionPrice] = useState(null);
  const [ratingSuggestionSupport, setRatingSuggestionSupport] = useState(null);

  //EXTRA FIELDS
  const [observations, setObservations] = useState(null);
  const [reasonToConsume, setReasonToConsume] = useState(null);
  const [recommendationScore, setRecommendationScore] = useState(null);

  //ORDER
  const [currentOrder, setCurrentOrder] = useState(null);
  const [currentReview, setCurrentReview] = useState(null);

  const [finished, setFinished] = useState(false);

  //ORDER FREQUENCY
  const [orderFrequency, setOrderFrequency] = useState(null);
  const [waystoIncrease, setWaysToIncrease] = useState(null);
  //EXTERNAL REVIEW DATA
  const [reviewData, setReviewData] = useState(null);

  //MODALS
  const [newAddressDialog, setNewAddressDialog] = useState(false);
  const [editCustomerDialog, setEditCustomerDialog] = useState(false);
  //MODALS

  const getReviewData = () => {
    getSingleData(locations.externalReviews, code)
      .then((res) => {
        console.log("res external review single data", res);

        setReviewData(res);
        setFinished(res.finished);

        //NOW GET THE ORDER
        if (res.data.length) {
          //setReviewData(res.data[0]);
          /* getData(locations.orders, {
            uuid: res.data[0].orderUUID,
          }).then((or) => {
            if (or.data.length) {
              setCurrentOrder(or.data[0]);
              if (res.data[0].finished) {
                setFinished(true);
              }
            }
            //console.log("order from uuid", or);
          }); */
        }
      })
      .catch((e) => {
        //console.log("e review", e);
      });
  };

  useEffect(() => {
    populateCompany(restaurantName).then((res) => {
      getReviewData();
    });
  }, []);

  const handleSubmitRating = () => {
    //CRIAR UM TOKEN PRO PATCH EXCLUSIVO DESSA PROPRIEDADE

    ////console.log("currentOrder", currentOrder.seq);

    let user = state.customer;
    delete user.addresses;

    const ratingObj = {
      customerPhone: user.phone,
      review: {
        recommendationScore: recommendationScore,
        orderFrequency: orderFrequency,
        waystoIncrease: waystoIncrease,
        generalNotes: observations,
      },
      finished: true,
      finishedAt: moment().toISOString(),
    };
    console.log("ratingObj", ratingObj);

    patchData(locations.externalReviews, reviewData._id, ratingObj)
      .then((res) => {
        console.log("review", res);
        setFinished(true);
        handleCloseModal();
      })
      .catch((e) => console.log(e));

    //THEN
    /* setTimeout(() => {
      //console.log("closing modal after then");
      handleCloseModal();
    }, 2000); */
    //PATCH DATA
  };

  /*   useEffect(() => {
    //console.log("moment", moment().toISOString());
  }, []); */

  function perc2color(perc, min, max) {
    var base = max - min;

    if (base == 0) {
      perc = 100;
    } else {
      perc = ((perc - min) / base) * 100;
    }
    var r,
      g,
      b = 0;
    if (perc < 50) {
      r = 255;
      g = Math.round(5.1 * perc);
    } else {
      g = 255;
      r = Math.round(510 - 5.1 * perc);
    }
    var h = r * 0x10000 + g * 0x100 + b * 0x1;
    return "#" + ("000000" + h.toString(16)).slice(-6);
  }

  function ColorLuminance(hex, lum) {
    // validate hex string
    hex = String(hex).replace(/[^0-9a-f]/gi, "");
    if (hex.length < 6) {
      hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }
    lum = lum || 0;

    // convert to decimal and change luminosity
    var rgb = "#",
      c,
      i;
    for (i = 0; i < 3; i++) {
      c = parseInt(hex.substr(i * 2, 2), 16);
      c = Math.round(Math.min(Math.max(0, c + c * lum), 255)).toString(16);
      rgb += ("00" + c).substr(c.length);
    }

    return rgb;
  }

  const RatingButtons = () => {
    let buttons = [];

    for (let i = 0; i <= 10; i++) {
      buttons.push(
        <Box
          sx={{
            width: {
              xs: "15px",
              md: "30px",
            },
            backgroundColor:
              recommendationScore !== i
                ? "#FFFFFF"
                : ColorLuminance(perc2color(i, 0, 10), -0.15),
            borderRadius: "5px",
            textAlign: "center",
            padding: "5px 2px",
            color:
              recommendationScore === i
                ? "#FFFFFF"
                : ColorLuminance(perc2color(i, 0, 10), -0.15),
            border: `2px solid ${ColorLuminance(perc2color(i, 0, 10), -0.15)}`,
          }}
          onClick={() => {
            //console.log("recommendation rate", i);
            setRecommendationScore(i);
          }}
          size="large"
        >
          <Typography sx={{ fontWeight: "bolder" }}>{i}</Typography>
        </Box>
      );
    }

    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        {buttons}
      </Box>
    );
  };

  ///RATING

  const handleOpenEditCustomerDialog = () => {
    setEditCustomerDialog(true);
  };

  const handleCloseEditCustomerDialog = () => {
    ////console.log("handle close currentCustomer", state.customer);
    setEditCustomerDialog(false);

    if (state.consumer) {
      //THERE'S A CONSUMER - OPEN BRANCHES DIALOG
      //CHECK MODALITY
      ////console.log("theres a consumer");
    } else {
      ////console.log("consumer state", state.consumer);
    }
  };

  useEffect(() => {
    console.log("reviewData", reviewData);
    if (reviewData) {
      if (!reviewData.finished) {
        handleOpenEditCustomerDialog();
      }
    }
  }, [reviewData]);

  return !reviewData ? (
    <Spinner />
  ) : (
    <>
      <SignInEdit
        isExternal={true}
        externalData={reviewData}
        editCustomerDialog={editCustomerDialog}
        handleCloseEditCustomerDialog={handleCloseEditCustomerDialog}
      />
      <Box>
        <Box
          sx={{
            padding: "25px",
          }}
        >
          {!finished ? (
            <>
              <Box
                sx={{
                  textAlign: "center",
                  paddingTop: "50px",
                  marginBottom: "25px",
                }}
              >
                {/* <StoreLogoHeader /> */}
                <StoreLogo
                  logo={
                    state.company &&
                    state.company.settings &&
                    state.company.settings.logoURL
                  }
                />
              </Box>
              <Box sx={{ textAlign: "left" }}>
                <Box sx={{ marginBottom: "10px" }}>
                  <Typography variant="h4" sx={{ textAlign: "center" }}>
                    Adorariamos saber sua opinião
                    <br /> sobre seu pedido.
                  </Typography>
                </Box>
                <Box sx={{ marginBottom: "15px" }}>
                  <Typography variant="h6" sx={{ textAlign: "center" }}>
                    É rapidinho! Basta completar a pesquisa abaixo :)
                  </Typography>
                </Box>
                {/* ITEM 1 */}
                {/* ITEM 1 */}
                {/* ITEM 1 */}
                {/* ITEM 1 */}
                {/* ITEM 1 */}
                <Box sx={{ marginBottom: "50px" }}>
                  <Box>
                    <Typography variant="h4">
                      1 - Com qual frequência você{" "}
                      <span style={{ fontWeight: "bolder" }}>
                        REALIZA NOVOS PEDIDOS
                      </span>
                      ?<span style={{ color: "#F00" }}>*</span>
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      marginTop: "15px",
                    }}
                  >
                    <Grid container spacing={1}>
                      <Grid item xs={12} md={3}>
                        <Box
                          onClick={() => setOrderFrequency("daily")}
                          sx={{
                            border:
                              orderFrequency === "daily"
                                ? "2px solid #2962ff"
                                : "2px solid #CCC",
                            borderRadius: "5px",
                            padding: "15px",
                            color: orderFrequency === "daily" ? "#FFF" : "#000",
                            backgroundColor:
                              orderFrequency === "daily" ? "#2962ff" : "#FFF",
                          }}
                        >
                          <Typography
                            variant="h5"
                            sx={{
                              fontSize: "15px",
                              textTransform: "uppercase",
                              textAlign: "center",
                            }}
                          >
                            Diariamente
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <Box
                          onClick={() => setOrderFrequency("weekly")}
                          sx={{
                            border:
                              orderFrequency === "weekly"
                                ? "2px solid #2962ff"
                                : "2px solid #CCC",
                            borderRadius: "5px",
                            padding: "15px",
                            color:
                              orderFrequency === "weekly" ? "#FFF" : "#000",
                            backgroundColor:
                              orderFrequency === "weekly" ? "#2962ff" : "#FFF",
                          }}
                        >
                          <Typography
                            variant="h5"
                            sx={{
                              fontSize: "15px",
                              textTransform: "uppercase",
                              textAlign: "center",
                            }}
                          >
                            Semanalmente
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <Box
                          onClick={() => setOrderFrequency("fortnightly")}
                          sx={{
                            border:
                              orderFrequency === "fortnightly"
                                ? "2px solid #2962ff"
                                : "2px solid #CCC",
                            borderRadius: "5px",
                            padding: "15px",
                            color:
                              orderFrequency === "fortnightly"
                                ? "#FFF"
                                : "#000",
                            backgroundColor:
                              orderFrequency === "fortnightly"
                                ? "#2962ff"
                                : "#FFF",
                          }}
                        >
                          <Typography
                            variant="h5"
                            sx={{
                              fontSize: "15px",
                              textTransform: "uppercase",
                              textAlign: "center",
                            }}
                          >
                            Quinzenalmente
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <Box
                          onClick={() => setOrderFrequency("montly")}
                          sx={{
                            border:
                              orderFrequency === "montly"
                                ? "2px solid #2962ff"
                                : "2px solid #CCC",
                            borderRadius: "5px",
                            padding: "15px",
                            color:
                              orderFrequency === "montly" ? "#FFF" : "#000",
                            backgroundColor:
                              orderFrequency === "montly" ? "#2962ff" : "#FFF",
                          }}
                        >
                          <Typography
                            variant="h5"
                            sx={{
                              fontSize: "15px",
                              textTransform: "uppercase",
                              textAlign: "center",
                            }}
                          >
                            Mensalmente
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                  {orderFrequency !== "daily" && (
                    <Box
                      sx={{
                        marginTop: "15px",
                      }}
                    >
                      <Typography variant="h5" sx={{ marginBottom: "10px" }}>
                        O que faria você consumir mais vezes na loja?
                      </Typography>
                      <TextField
                        fullWidth
                        placeholder="Digite sua resposta"
                        onChange={(e) => {
                          setWaysToIncrease(e.target.value);
                        }}
                      />
                    </Box>
                  )}
                </Box>
                {/* ITEM 1 */}
                <Box sx={{ marginBottom: "50px" }}>
                  <Box>
                    <Typography
                      variant="h4"
                      sx={{
                        marginBottom: "10px",
                      }}
                    >
                      2 - Em uma escala de 0 a 10, o quanto você recomendaria
                      para um amigo ou familiar?
                      <span style={{ color: "#F00" }}>*</span>
                    </Typography>
                  </Box>
                  <RatingButtons />
                </Box>
                <Box sx={{ marginBottom: "50px" }}>
                  <Box>
                    <Typography
                      variant="h4"
                      sx={{
                        marginBottom: "15px",
                      }}
                    >
                      3 - Informe aqui sua sugestão ou elogio
                    </Typography>
                  </Box>
                  <TextField
                    multiline
                    fullWidth
                    rows={4}
                    sx={{ marginBottom: "10px" }}
                    onChange={(e) => setObservations(e.target.value)}
                  />
                </Box>
                <Button
                  fullWidth
                  variant="contained"
                  onClick={() => handleSubmitRating()}
                  disabled={!orderFrequency || !recommendationScore}
                >
                  Concluir
                </Button>
              </Box>
            </>
          ) : (
            <Box>
              {" "}
              <Box
                sx={{
                  textAlign: "center",
                  paddingTop: "50px",
                  marginBottom: "25px",
                }}
              >
                {/* <StoreLogoHeader /> */}
                <StoreLogo
                  logo={
                    state.company &&
                    state.company.settings &&
                    state.company.settings.logoURL
                  }
                />
              </Box>
              <Box sx={{ marginBottom: "25px" }}>
                <Typography
                  variant="h4"
                  sx={{ textAlign: "center", fontSize: "1.5rem" }}
                >
                  Obrigado por avaliar seu pedido!
                </Typography>
              </Box>
              <Box
                sx={{
                  marginBottom: "25px",
                  textAlign: "center",
                }}
              >
                <Typography variant="h4">
                  {reviewData.couponDescription}
                </Typography>
              </Box>
              <Box
                sx={{
                  padding: "25px",
                  border: "2px solid #CCC",
                  borderRadius: "5px",
                  textAlign: "center",
                }}
              >
                <Typography variant="h2">{reviewData.couponCode}</Typography>
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </>
  ); /*  : (
    <Typography>Pedido não encontrado</Typography>
  ); */
};

export default ExternalReviewPage;
