import React, { useEffect } from "react";
import { Grid, Box, Button } from "@mui/material";

import {
  BlogCard,
  SalesOverview,
  ProductPerformance,
  DailyActivities,
  ProductsList,
} from "./dashboard1-components";
import HeaderBanners from "./HeaderBanners";
import "./dashboard1-components/productListStyles.css";
import { useApp } from "../../context/appContext";
import CheckoutSpinner from "../checkout/Spinner/Spinner";
import calculateCouponDiscount from "../../utils/calculateCouponDiscount";
import calculateTotalPrice from "../../utils/calculateTotalCart";
import OffersList from "./dashboard1-components/OffersList";

const Dashboard1 = () => {
  // 2

  const { state, registerSessionActivity } = useApp();

  const total = calculateTotalPrice(state.cart);

  const printState = () => {
    ////console.log("full state", state);
  };

  useEffect(() => {
    if (state.branch) {
      console.log("branch selected");
      registerSessionActivity(
        {
          type: "BROWSING_MENU",
          branch: state.branch && state.branch._id,
        },
        state.branch
      );
    } else {
      console.log("theres no branch in the state");
    }
  }, [state.branch]);

  return (
    <Box>
      <Grid className="removeMargins" container spacing={0}>
        {/* ------------------------- row 1 ------------------------- */}
        <Grid className="removeMargins" item xs={12} lg={12}>
          <HeaderBanners branch={state.branch} />
        </Grid>
        <Grid className="removeMargins" item xs={12} lg={12}>
          {state.loaded ? (
            <>
              {state.products.filter((p) => p.offer).length !== 0 && (
                <OffersList />
              )}
              <ProductsList />
            </>
          ) : (
            <CheckoutSpinner />
          )}
          {/* <Button onClick={() => printState()}>PRINTAR STATE</Button> */}
        </Grid>
        {/* ------------------------- row 2 ------------------------- */}
        {/* <Grid item xs={12} lg={4}>
          <DailyActivities />
        </Grid>
        <Grid item xs={12} lg={8}>
          <ProductPerformance />
        </Grid> */}
        {/* ------------------------- row 3 ------------------------- */}
        {/* <BlogCard /> */}
      </Grid>
    </Box>
  );
};

export default Dashboard1;
