import React, { useState, useEffect } from "react";
import {
  Alert,
  Grid,
  Box,
  Typography,
  Divider,
  Button,
  TextField,
  Dialog,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { Link, NavLink } from "react-router-dom";
import "../../../global.css";
import calculateTotalPrice from "../../../utils/calculateTotalCart";
import { SentimentSatisfied } from "@mui/icons-material";

const CashDialog = ({
  handleCloseCashDialog,
  openCashDialog,
  cashChange,
  setCashChange,
  cart,
  deliveryFee,
}) => {
  const [selectedNeighborhood, setSelectedNeighborhood] = useState(null);
  const [disabledChange, setDisabledChange] = useState(false);

  const cartSubtotal = calculateTotalPrice(cart);
  const total = cartSubtotal + deliveryFee;

  const handleChange = (e) => {
    const change = parseFloat(e);

    if (total > change) {
      //setCashChange(e);
      ////console.log("change must be higher than total price");
      setDisabledChange(true);
    } else {
      ////console.log(e);
      setCashChange(e);
      setDisabledChange(false);
    }
  };

  useState(() => {
    if (cashChange === "" || cashChange === 0) {
      setDisabledChange(false);
    }
  }, [cashChange]);

  return (
    <Dialog
      onClose={() => {
        handleCloseCashDialog();
      }}
      open={openCashDialog}
    >
      <Box sx={{ padding: "25px", width: { md: "400px" } }}>
        <div className="wrapperData" style={{ marginBottom: "50px" }}>
          <Box
            lg={{
              textAlign: "center",
            }}
          >
            <Typography
              variant="h2"
              lg={{
                width: "100%",
                textAlign: "center",
              }}
            >
              Deseja troco?
            </Typography>
            <Typography
              variant="h6"
              lg={{
                width: "100%",
                textAlign: "center",
                marginBottom: "25px",
              }}
            >
              Troco para quanto? Deixe o campo vazio caso não precise
            </Typography>
          </Box>
          {disabledChange && (
            <Alert
              sx={{
                marginTop: "15px",
              }}
              iconMapping={{
                info: <SentimentSatisfied fontSize="inherit" />,
              }}
              severity="info"
            >
              <strong>Atenção:</strong>
              <br />
              Informe um valor superior ao valor total
            </Alert>
          )}
          <Box style={{ marginTop: "25px" }}>
            <TextField
              onChange={(e) => handleChange(e.target.value)}
              id="default-textarea"
              label="R$"
              type="number"
              autoComplete="off"
              variant="outlined"
              fullWidth
              sx={{
                mb: 2,
              }}
            />
          </Box>
          <Box style={{ width: "100%" }}>
            <Button
              disabled={disabledChange}
              onClick={() => handleCloseCashDialog()}
              fullWidth
              variant="contained"
            >
              Confirmar
            </Button>
          </Box>
        </div>
      </Box>
    </Dialog>
  );
};
export default CashDialog;
